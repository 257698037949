import { gql } from '@apollo/client';

export const GET_MAIN = gql`
    query GetMain {
        main {
            url
            description
        }
    }
`;

export const GET_PRODUCT = gql`
    query GetProduct($productName: String!) {
        product(productName: $productName) {
            id
            name
            image
            description
            cards {
                title
                value {
                    title
                    value
                }
            }
            gallery {
                url
                description
                thumbnail_url
            }
        }
    }
`;