import React, { Component } from 'react'
import Image from "react-bootstrap/Image";
import Figure from 'react-bootstrap/Figure'
import ImageModal from './ImageModal'
import './GalleryImage.css'

class GalleryImage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            'modalDetail': {
                'show': false
            }
        };

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    showModal() {
        this.setState({
            'modalDetail': {
                'show': true,
                'src': this.props.full,
                'alt': this.props.children,
                'onHide': this.hideModal
            }
        })
    }

    hideModal() {
        this.setState({
            'modalDetail': {
                'show': false
            }
        })
    }

    render() {
        const { modalDetail } = this.state;

        return (
            <>
                <Figure className="gallery-image-wrapper">
                    <Image className={"figure-img rounded cursor-pointer gallery-image"} src={this.props.src} onClick={this.showModal} />
                    <Figure.Caption className={'text-center'}>
                        {this.props.children}
                    </Figure.Caption>
                </Figure>
                <ImageModal {...modalDetail} />
            </>
        )
    }
}

export default GalleryImage