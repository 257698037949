import React from 'react'
import Modal from 'react-bootstrap/Modal'

const imageModal = (props) => {
    return (
        <>
            <Modal
                show={props.show}
                size="lg"
                centered={true}
                onHide={props.onHide}
                dialogClassName="modal-90w"
            >
                <Modal.Header closeButton/>
                <Modal.Body>
                    <div className={'text-center'}>
                        <img src={props.src} alt={props.alt} className={'img-fluid'} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default imageModal;