import React from 'react';
import TbcHeaderText from './Tbc-Header-Text';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from "react-bootstrap/Card";

const CDN_PATH = process.env.REACT_APP_CDN_URL;

const seasonal = () => {
    return (
        <div>
            <Row className={'mb-2'}>
                <Col>
                    <TbcHeaderText>Seasonal</TbcHeaderText>
                </Col>
            </Row>

            <Row className={'mb-4'}>
                <Card className={'mx-auto description-card'}>
                    <Card.Img variant="top" src={`${CDN_PATH}/images/sugar_cookies.jpg`} />
                    <Card.Body>
                        <Card.Title>Custom Sugar Cookies</Card.Title>
                        <p>Our sugar cookies come in your choice of shapes and themes. Order your holiday treats soon!</p>
                        <p>$36 per dozen, or $48 per dozen for individually wrapped favors </p>
                        <p>One dozen minimum for pickup.</p>
                    </Card.Body>
                </Card>
                <Card className={'mx-auto description-card'}>
                    <Card.Img variant="top" src={`${CDN_PATH}/images/mug_buddies.jpg`} />
                    <Card.Body>
                        <Card.Title>Mug Buddies</Card.Title>
                        <p>Mug Buddies are sugar cookies that hang independently on the side of a mug, making them a perfect treat you can enjoy with your hot holiday beverages.</p>
                        <p>They’re even better as a present combined with a cocoa or latte bomb!</p>
                        <p>$3 each, or $4 individually wrapped with a bow.  Contact for different shapes and styles.</p>
                    </Card.Body>
                </Card>
                <Card className={'mx-auto description-card'}>
                    <Card.Img variant="top" src={`${CDN_PATH}/images/coco_bombs.jpg`} />
                    <Card.Body>
                        <Card.Title>Coco & Latte Bombs</Card.Title>
                        <p>Cocoa and Latte bombs are chocolate spheres containing a powder drink mix. Place in your favorite mug, pour hot water or milk (4-5oz) over, and watch them melt and stir to make a delicious drink.</p>
                        <p>Our Cocoa Bombs contain sprinkles and marshmallows and are decorated as melting snowmen.</p>
                        <p>Our Latte Bombs contain either coffee or tea powder. Our flavors are London Fog, Chai, White Mocha, and Lavender Tea.</p>
                        <p>$5 each</p>
                    </Card.Body>
                </Card>
            </Row>
        </div>
    );
};
export default seasonal;