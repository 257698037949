import React from 'react';
import FacebookImage from '../img/facebook-01.png';
import InstagramImage from '../img/instagram-01.png';
import TwitterImage from '../img/twitter-01.png';
import './Social-Link.css';

const SocialLink = (props) => {
    return (
        <a
        href={props.href}
        target="_blank"
        rel="noopener noreferrer">
            <img src={props.src} alt={props.alt} className="social-link" />
        </a>
    );
};

export const FacebookLink = () => {
    return <SocialLink href="https://www.facebook.com/thumbprintsbakingco" src={FacebookImage} alt="Facebook" />
};

export const InstagramLink = () => {
    return  <SocialLink href="https://www.instagram.com/thumbprintsbake/" src={InstagramImage} alt="Instagram" />
};

export const TwitterLink = () => {
    return <SocialLink href="https://twitter.com/thumbprintsbake" src={TwitterImage} alt="Twitter" />
};