import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './components/App';
import { ApolloClient, ApolloLink, InMemoryCache, HttpLink, ApolloProvider } from '@apollo/client';
import * as serviceWorker from './serviceWorker';

const authLink = new ApolloLink((operation, forward) => {
    operation.setContext({
        headers: {
            "x-api-key": process.env.REACT_APP_API_KEY
        }
    });

    return forward(operation);
});

const client = new ApolloClient({
    link: authLink.concat(new HttpLink({ uri: process.env.REACT_APP_API_URL })),
    cache: new InMemoryCache()
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ApolloProvider client={client}>
        <App />
    </ApolloProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
