import React from 'react';
import TbcHeaderText from './Tbc-Header-Text';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ProductHeaderImage from './ProductHeaderImage'
import DescriptionCard from './DescriptionCard'
import DescriptionSubtitleSection from './DescriptionSubtitleSection'
import { useQuery } from '@apollo/client';
import { GET_PRODUCT } from '../gql/fetch';

import GalleryImage from './GalleryImage'

const Product = () => {
    const { loading, error, data } = useQuery(GET_PRODUCT, {
        variables: {
            "productName": window.location.href.split('/').pop()
        },
    });

    if (loading) return null;
    if (error) return null;
    if (!data) return null;

    const imageGallery = 'col-xs-12 text-center col-lg-4 thumb';
    const colCardClasses = 'col-sm-12 col-md-4';

    const { product } = data;

    return (
        <div>
            <Row className={'mb-2'}>
                <Col>
                    <TbcHeaderText>{product.name}</TbcHeaderText>
                </Col>
            </Row>

            <Row className={'mb-4'}>
                <Col className={'text-center'}>
                    <ProductHeaderImage
                        src={product.image}
                    />
                </Col>
            </Row>

            {product.description &&
            <Row className={'mb-2'}>
                <Col>
                    <p className={'text-center'}>{product.description}</p>
                </Col>
            </Row>
            }

            <Row>
                {product.cards.map((title, mainIndex) => {
                    return (
                        <Col className={colCardClasses} key={`${product.id}-cards-${mainIndex}`}>
                            <DescriptionCard title={title.title}>
                                {title.value.map((subTitle, subIndex) => {
                                    if (subTitle.title === "") {
                                        return (
                                            <span dangerouslySetInnerHTML={{__html: subTitle.value}} />
                                        )
                                    }

                                    return (
                                        <DescriptionSubtitleSection
                                            title={subTitle.title}
                                            key={`${product.id}-cards-${mainIndex}-subtitle-${subIndex}`}
                                        >
                                            <span dangerouslySetInnerHTML={{__html: subTitle.value}} />
                                        </DescriptionSubtitleSection>
                                    )

                                })}
                            </DescriptionCard>
                        </Col>
                    )
                })}
            </Row>

            <Row className={'my-2'}>
                {product.gallery.map((key, index) => {
                    return (
                        <Col className={imageGallery} key={`${product.id}-gallery-${index}`}>
                            <GalleryImage src={key.thumbnail_url} full={key.url}>
                                {key.description}
                            </GalleryImage>
                        </Col>
                    );
                })}
            </Row>
        </div>
    );
};
export default Product;