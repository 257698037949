import React from 'react';
import TbcHeaderText from './Tbc-Header-Text';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import KandM from '../img/kandm.jpg'
import RandE from '../img/Thumbprint-Bakery-Co-029.jpg'

const about = () => {
    // 640x513
    return (
        <div>
            <TbcHeaderText>About Us</TbcHeaderText>
            <Row className={'mb-5'}>
                <Col sm={12} md={12} lg={6}>
                    <Image src={KandM} height={350} />
                </Col>
                <Col sm={12} md={12} lg={6}>
                    <p>Two sisters once dreamed up the best coffee shop ever, where you could get a cup of coffee, an otherworldly scone, and hang out while your little kids went wild in the amazing play area. When the sister who had actually worked in coffee shops gently suggested a small online operation to first test the bakery waters, Thumbprints Baking Co. was born. Inspired by the cafe in the small town in Italy where they grew up, Katie Arceo and Maggie Cheung started out taking orders for specialty cookie assortments as well as cakes, cupcakes, and tarts.</p>
                </Col>
            </Row>

            <Row className={'mb-5'}>
                <Col sm={12} md={12} lg={6}>
                    <Image src={RandE} height={350} />
                </Col>
                <Col sm={12} md={12} lg={6}>
                    <p>Three years later Thumbprints has narrowed and improved its focus and skills, specializing in the products its customers order most: custom theme sugar cookies for all occasions and French macarons in a rainbow of flavors and colors. Katie Arceo, a trained pastry chef with experience in many of Seattle's favorite bakeries, bakes, decorates, and runs the cookie empire from her licensed home kitchen in the South Sound. Older sister Maggie Cheung retains only her Chief Taste Tester title while cheerfully plying the internet with cookie pictures.</p>
                </Col>
            </Row>

            <Row>
                <p>Thumbprints Baking Co. provides the Seattle Tacoma area with sweet treats for weddings, corporate events, parties, and showers. As your custom cookie baker, Katie Arceo brings fresh flavors, bright ideas, and personal attention to your event or special occasion. Contact her today with your cookie needs!</p>
            </Row>

            <Row>
                <h2>Email your orders and questions to: <a href={"orders@thumbprintsbakingco.com"}>orders@thumbprintsbakingco.com</a>.</h2>
            </Row>
        </div>
    );
};
export default about;