import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Navigation from './Navigation';
import Footer from './Footer';
import Home from "./Home";
import About from "./About";
import Product from './Product';
import Seasonal from './Seasonal';

import './App.css';

class App extends Component {
  render() {
    return (
        <Router>
            <div>
                <Navigation />

                <Container>
                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/seasonal" element={<Seasonal />}/>
                        <Route path="/sugar-cookies" element={<Product />} />
                        <Route path="/macarons" element={<Product />} />
                        {/* <Route path="/cakes" element={<Product />} /> */}
                    </Routes>
                </Container>

                <Footer />
            </div>
        </Router>
    );
  }
}

export default App;
